export default {
    name: "global",
    store() {
        return {
            isMobileMenuVisible: false,
            isMinicartVisible: false,
            isImageModalVisible: false,
            modalImageSrc: "",
            isPredictiveSearchVisible: false,
            isWindowScrolled: false,
            cart: null,
            get bodyClasses() {
                let classes = [];

                if (this.isMobileMenuVisible) {
                    classes.push("mobile-menu-visible");
                }

                return classes || "";
            },
            openMobileMenu() {
                this.isMobileMenuVisible = true;
                document.body.classList.add("overflow-hidden");
            },
            closeMobileMenu() {
                this.isMobileMenuVisible = false;
                document.body.classList.remove("overflow-hidden");
                document.body.classList.add("overflow-auto");
            },
            toggleMobileMenu() {
                this.isMobileMenuVisible = !this.isMobileMenuVisible;
                if (this.isMobileMenuVisible) {
                    document.body.classList.add("overflow-hidden");
                } else {
                    document.body.classList.remove("overflow-hidden");
                }
            },
        };
    },
};
