export default function () {
  const buttons = [
    ...document.querySelectorAll(".js-scroll-to-video-showcase"),
  ];

  buttons.forEach((btn) => {
    const elementToScrollToId = btn.getAttribute("data-scroll-to");

    btn.addEventListener("click", () => {
      const element = document.getElementById(elementToScrollToId);
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    });
  });
}
