export default function filterCategory() {
    const result = document.querySelector(".js-col-items");
    const output = document.querySelector(".js-category-output");

    const categoryList = [];
    const map = [];

    if (result && output) {
        const items = [...result.children];

        items.map((item) => {
            const json = item.getAttribute("data-categories");
            const data = JSON.parse(json);
            let categories = [];

            if (data) {
                data.forEach((info) => {
                    categories.push(info.title);
                    if (!categoryList.includes(info.title)) categoryList.push(info.title);
                });

                map.push({
                    element: item,
                    categories: categories,
                });
            }
        });
    }

    categoryList.forEach((category) => {
        const cont = document.createElement("div");
        const button = document.createElement("button");
        const input = document.createElement("input");

        cont.classList = "flex items-baseline gap-2";
        input.type = "checkbox";
        input.classList = "bg-black p-2 h-auto text-white";
        button.classList = "block whitespace-pre";

        if (category != "Default Title") {
            button.innerHTML = category;
        }

        if (category == "Alla väskor" && window.Shopify.locale == "de") {
            button.innerHTML = "Alle Taschen";
        }

        if (category == "All bags" && window.Shopify.locale == "en") {
            button.innerHTML = "All bags";
        }

        let checked = true;

        cont.addEventListener("click", () => {
            checked = !checked;

            map.forEach((item) => {
                if (item.categories.includes(category) || checked || category == "Default Title") {
                    item.element.style.display = "block";
                } else {
                    item.element.style.display = "none";
                }
            });

            const inputs = [...output.querySelectorAll("input")];
            inputs.map((i) => {
                i.checked = i === input && !checked;
            });
        });

        cont.appendChild(input);
        cont.appendChild(button);
        output.appendChild(cont);
    });
}
