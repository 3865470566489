export default function linkblockButtons() {
  const linkblocks = [...document.querySelectorAll(".js-linkblock")];

  linkblocks.forEach((cont) => {
    let index = 0;

    const btnForward = cont.querySelector(".js-forward");
    const btnBackward = cont.querySelector(".js-backward");

    const itemContainer = cont.querySelector(".js-items");
    const items = [...itemContainer.children];

    itemContainer.scrollTo({
      left: 0,
      behavior: "smooth",
    });

    btnForward.addEventListener("click", () => {
      index++;
      if (index === items.length) index = 0;

      // Scroll to the second element inside itemContainer
      itemContainer.scrollTo({
        left: items[index].offsetLeft,
        behavior: "smooth",
      });
    });

    btnBackward.addEventListener("click", () => {
      index--;
      if (index === -1) index = items.length - 1;

      // Scroll to the second element inside itemContainer
      itemContainer.scrollTo({
        left: items[index].offsetLeft,
        behavior: "smooth",
      });
    });
  });
}
