export default () => {
    document.querySelectorAll('.container ul li').forEach((li) => {
        const button = li.querySelector('button');
        const iconPlus = li.querySelector('.icon-plus');
        const iconMinus = li.querySelector('.icon-minus');
        const content = li.querySelector('.space-y-4');

        button.addEventListener('click', () => {
            const isOpen = content.classList.contains('hidden');

            content.classList.toggle('hidden', !isOpen);
            iconPlus.classList.toggle('hidden', isOpen);
            iconMinus.classList.toggle('hidden', !isOpen);
        });
    });
};