// Function to handle submenu opening
function handleSubMenuOpen(parents, children) {
    parents.forEach((item, i) => {
        item.addEventListener("click", () => {
            children[i].classList.add("open");
        });
    });
}

// Function to handle submenu closing
function handleSubMenuClose(closeSubMenuButtons, children) {
    closeSubMenuButtons.forEach((item) => {
        item.addEventListener("click", () => {
            children.forEach((child) => {
                child.classList.remove("open");
            });
        });
    });
}

// Function to handle all menus closing
function handleAllMenusClose(button, nav, children) {
    button.addEventListener("click", () => {
        nav.classList.toggle("open");
        Array.from(button.children).forEach((child, index) => {
            // If nav is open, show the close icon (second child), otherwise show the hamburger icon (first child)
            if (nav.classList.contains("open")) {
                if (index === 1) {
                    child.classList.remove("hidden");
                } else {
                    child.classList.add("hidden");
                }
            } else {
                if (index === 0) {
                    child.classList.remove("hidden");
                } else {
                    child.classList.add("hidden");
                }
            }
        });
        children.forEach((item) => {
            item.classList.remove("open");
        });

        if (nav.classList.contains("open")) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    });
}

export default function mobileNav() {
    const header = document.getElementById("shopify-section-header");
    const button = document.querySelector(".js-hamburger");
    const closeButton = document.getElementById("close-mobile-nav"); 
    const nav = document.querySelector(".js-nav");

    if (nav && button && closeButton) {
        const parents = [...nav.querySelectorAll(".header-parent")];
        const children = [...nav.querySelectorAll(".header-child")];
        const closeSubMenuButtons = [...nav.querySelectorAll(".js-close-child")];

        handleSubMenuOpen(parents, children);
        handleSubMenuClose(closeSubMenuButtons, children);
        handleAllMenusClose(button, nav, children);
        handleAllMenusClose(closeButton, nav, children);
        updateTopPlacement(header, children);
        const resizeObserver = new ResizeObserver(() => updateTopPlacement(header, children));
        resizeObserver.observe(header);

        window.addEventListener("scroll", () => updateTopPlacement(header, children));
    }
}

function updateTopPlacement(header, children) {
    if (window.innerWidth > 768) {
        const headerHeight = header.offsetHeight;
        children.forEach((child) => {
            child.style.top = `calc(${headerHeight}px - 20px)`;
        });
    }
}

function updatePaddingTop() {
    const header = document.getElementById("shopify-section-header");
    const headerHeight = header.offsetHeight;
    document.body.style.paddingTop = `${headerHeight}px`;
}

function observeHeaderSize() {
    const resizeObserver = new ResizeObserver(updatePaddingTop);
    resizeObserver.observe(document.getElementById("shopify-section-header"));
}

updatePaddingTop();
observeHeaderSize();
