const slideshow = document.querySelector(".js-slideshow-single");
const items = slideshow ? [...slideshow.children] : [];

const bulletContainer = document.querySelector(".js-product-gallery-bullets");
const bulletClone = bulletContainer ? [...bulletContainer.children][0].cloneNode(true) : null;

function getVisibleItems() {
    return items.filter((item) => !item.classList.contains("hidden") && item.querySelector("img, video"));
}

function scrollToVideoButton() {
    const btn = document.querySelector(".js-scroll-to-video");
    if (btn) {
        btn.addEventListener("click", () => {
            const visibleItems = getVisibleItems();
            const videoItem = visibleItems.find((item) => item.querySelector("video"));
            if (videoItem) {
                const video = videoItem.querySelector("video");
                video.pause();
                video.currentTime = 0;
                slideshow.scrollTo(videoItem.offsetLeft, 0);
                video.play();
            }
        });
    }
}

function createBullets() {
    bulletContainer.innerHTML = "";
    const visibleItems = getVisibleItems();
    const visibleItemsAmount = visibleItems.length;

    for (let i = 0; i < visibleItemsAmount; i++) {
        if (!bulletClone) {
            continue;
        }

        const bullet = bulletClone.cloneNode(true);
        const bulletImage = bullet.querySelector("img");
        const mediaItem = visibleItems[i].querySelector("img, video");

        if (bulletImage && mediaItem) {
            bulletImage.src = mediaItem.src;
        } else {
            continue;
        }

        bullet.addEventListener("click", () => {
            slideshow.scrollTo(visibleItems[i].offsetLeft, 0);
        });
        bulletContainer.appendChild(bullet);
    }

    window.swiper = new Swiper(".swiper-product-images-bullets", {
        modules: [Navigation, Scrollbar],
        slidesPerView: 6,
        spaceBetween: 5,
        navigation: {
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
            disabledClass: "disabled",
        },
        scrollbar: {
            el: ".swiper-scrollbar",
            hide: false,
        },
    });
}

function checkActiveBullet() {
    slideshow.addEventListener("scroll", () => {
        updateBullets();
    });
}

function updateBullets() {
    const currentScroll = slideshow.scrollLeft;
    const scrollIndex = Math.floor(currentScroll / slideshow.offsetWidth);

    const bullets = [...bulletContainer.children];
    bullets.forEach((bullet, index) => {
        if (index === scrollIndex) {
            bullet.classList.add("border-black");
        } else {
            bullet.classList.remove("border-black");
        }
    });
}

export function resetSlideshowScroll() {
    slideshow.scrollTo(0, 0);
    createBullets();
    updateBullets();
}

export default function slideShow() {
    if (slideshow) {
        slideshow.scrollTo(0, 0);
        scrollToVideoButton();

        createBullets();
        checkActiveBullet();
        updateBullets();

        window.addEventListener("resize", createBullets);
    }
}
