import productVariantSelectorSinglePage from "../js/pages/product-single/productVariantSelectorSinglePage.mjs";
import navigationMenu from "../js/sections/navigation.mjs";
import cart from "../js/sections/cart.mjs";
import accordion from "../js/sections/accordion.mjs";
import faq from "../js/pages/faq/faq.mjs";
import filterCategory from "../js/pages/collections/filter/filterCategory.mjs";
import filterAccordions from "../js/pages/collections/filter/filterAccordions.mjs";
import linkblockButtons from "../js/sections/linkblockButtons.mjs";
import zoomImage from "../js/pages/product-single/zoomImage.mjs";
import redirects from "../js/scripts/redirect.mjs";
import scrollToBagShowcase from "../js/pages/product-single/scrollToBagShowcase.mjs";
import "../js/swiperSetup.js";
import slideShowSingle from "../js/pages/product-single/slideshow.mjs";
import Hotjar from "@hotjar/browser";

redirects();

// Alpine
import Alpine from "alpinejs";
import AlpineCollapse from "@alpinejs/collapse";
import AlpineFocus from "@alpinejs/focus";
import AlpineMorph from "@alpinejs/morph";
import AlpineWire from "../js/alpine/plugins/wire";
import AlpineGlobals from "../js/alpine/index.js";

// Register and initialize AlpineJS
window.Alpine = Alpine;

Alpine.plugin([AlpineCollapse, AlpineFocus, AlpineWire, AlpineMorph]);
AlpineGlobals.register(Alpine);
Alpine.start();

document.addEventListener("DOMContentLoaded", function () {
    var images = document.querySelectorAll(".focal-point-image");
    images.forEach(function (image) {
        var focalX = parseFloat(image.getAttribute("data-focal-x")) || 0.5;
        var focalY = parseFloat(image.getAttribute("data-focal-y")) || 0.5;
        image.style.objectPosition = focalX + "% " + focalY + "%";
    });
});

window.onload = async () => {
    const siteId = 3873551;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);

    if (window.location.href.includes("/products/")) {
        await productVariantSelectorSinglePage();
        slideShowSingle();
        zoomImage();
        scrollToBagShowcase();
    }

    //Modules
    linkblockButtons();

    //Collection page
    filterCategory();
    filterAccordions();

    //Header
    navigationMenu();
    cart();

    accordion();

    //Faq page
    faq();
};

//Autoplay videos
document.addEventListener("DOMContentLoaded", function () {
    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

    if ("IntersectionObserver" in window) {
        var lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (video) {
                if (video.isIntersecting) {
                    for (var source in video.target.children) {
                        var videoSource = video.target.children[source];
                        if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                            videoSource.src = videoSource.dataset.src;
                        }
                    }

                    video.target.load();
                    video.target.classList.remove("lazy");
                    lazyVideoObserver.unobserve(video.target);
                }
            });
        });

        lazyVideos.forEach(function (lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
        });
    }
});
