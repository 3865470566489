export default () => {
    const container = document.querySelector(".js-zoom-image");
    if (!container) return;

    const buttons = document.querySelectorAll(".js-zoom-image-button");

    const buttonNext = document.querySelector("#js-zoom-image-next");
    const buttonPrev = document.querySelector("#js-zoom-image-prev");

    const btnClose = document.querySelector("#js-zoom-image-close");

    const images = [...document.querySelectorAll(".js-product-image")];
    if (!images.length) return;

    const imgContainer = container.children[0];

    let visibleImages = [];
    let currentImage = null;
    let index = null;

    function hideElements() {
        container.classList.add("hidden");
        document.body.classList.remove("overflow-hidden");

        buttons.forEach((button) => {
            button.classList.add("hidden");
        });
    }

    function showElements() {
        container.classList.remove("hidden");
        document.body.classList.add("overflow-hidden");

        buttons.forEach((button) => {
            button.classList.remove("hidden");
        });
    }

    function changeImage(forwards) {
        index = visibleImages.indexOf(currentImage);
        index = forwards ? index + 1 : index - 1;
        index = index < 0 ? visibleImages.length - 1 : index % visibleImages.length;

        currentImage = visibleImages[index];
        imgContainer.src = visibleImages[index].src;
    }

    function getImageSrc(img) {
        const viewportWidth = window.innerWidth;
        let src;
    
        if (viewportWidth <= 768) {
            src = img.dataset.smallSrc;
        } else if (viewportWidth <= 1024) {
            src = img.dataset.mediumSrc;
        } else {
            src = img.dataset.largeSrc;
        }
    
        return src;
    }
    
    images.forEach((img) => {
        img.addEventListener("click", () => {
            if (img.classList.contains("open")) {
                img.classList.remove("open");
                hideElements();
            } else {
                img.classList.add("open");
                showElements();
                imgContainer.src = getImageSrc(img); // Use the new function here
                currentImage = img;
                visibleImages = images.filter((img) => !img.parentElement.classList.contains("hidden"));
            }
        }); 
    });

    container.addEventListener("click", (e) => {
        if (e.target === container) {
            hideElements();
        }
    });

    btnClose.addEventListener("click", hideElements);
    buttonNext.addEventListener("click", () => changeImage(true));
    buttonPrev.addEventListener("click", () => changeImage(false));
};