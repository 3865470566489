export default function filterAccordions(){

   const accordions = [...document.querySelectorAll(".js-accordion")]

   accordions.forEach(acc => {
      const button = acc.children[0]
      const container = acc.children[1]

      button.addEventListener("click", () => {
         container.classList.toggle("max-h-auto")
         container.classList.toggle("max-h-0")
         container.classList.toggle("p-4")
         button.children[1].classList.toggle("rotate-180")
      })
   })


}